<template>
  <div>
    <div class="coursedetail_page">
      <van-nav-bar left-text="❮返回" @click-left="$router.go(-1)"></van-nav-bar>
      <div class="coursedetail_con">
        <div class="total">
          <ul>
            <li>
              <i class="el-icon-folder"></i>
              <span>项目：{{ courseDetail.item_num }}</span>
            </li>
            <li>
              <i class="el-icon-folder-remove"></i>
              <span>训练中项目：{{ courseDetail.train_num }}</span>
            </li>
            <li>
              <van-icon name="completed" />
              <span>通过项目：{{ courseDetail.adopt_num }}</span>
            </li>
            <li>
              <van-icon name="todo-list-o" />
              <span>复习项目：{{ courseDetail.rev_num }}</span>
            </li>
          </ul>
        </div>
        <div class="list">
          <div class="tabs">
            <div class="tab" :class="{ active: activeTab === 0 }" @click="tabChange(0)">训练项目</div>
            <div class="tab" :class="{ active: activeTab === 1 }" @click="tabChange(1)">复习项目</div>
          </div>
          <div class="table_box">
            <el-table v-if="activeTab === 0" :data="courseDetail.list" stripe>
              <el-table-column prop="main_name" label="维度" min-width="11%" align="center">
                <template slot-scope="scope">
                  <p
                    v-html="
                      scope.row.manor_name + '><br>' + scope.row.main_name
                    "
                  ></p>
                </template>
              </el-table-column>
              <el-table-column prop="project_name" label="项目" min-width="16%" align="center"></el-table-column>
              <el-table-column
                prop="target"
                label="目标"
                min-width="30%"
                align="center"
                v-if="
                  $route.query.teach_type == 3 || $route.query.teach_type == 4
                "
              ></el-table-column>
              <el-table-column
                prop="train"
                label="目标完成情况"
                min-width="10%"
                align="center"
                v-if="
                  $route.query.teach_type == 3 || $route.query.teach_type == 4
                "
              >
                <template slot-scope="scope">
                  <p>
                    {{
                    ["", "独立完成", "半辅", "全辅"][scope.row.train&&scope.row.train[0].key]
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="is_adopt" label="项目状态" min-width="8%" align="center">
                <template slot-scope="scope">
                  <p
                    v-if="$route.query.teach_type == 1 || $route.query.teach_type == 2"
                  >{{ ["训练中", "已通过"][scope.row.is_adopt] }}</p>
                  <p
                    v-if="$route.query.teach_type == 3 || $route.query.teach_type == 4"
                  >{{ ["训练中", "已通过"][scope.row.is_train] }}</p>
                </template>
              </el-table-column>
              <el-table-column
                prop="train"
                label="训练记录"
                min-width="26%"
                align="center"
                v-if="
                  $route.query.teach_type == 1 || $route.query.teach_type == 2
                "
              >
                <template slot-scope="scope">
                  <div class="train">
                    <span>
                      {{
                      scope.row.big &&
                      scope.row.big.length > 0
                      ? scope.row.big.map((e) => ["N", "Y"][e.key]).join("")
                      : scope.row.small && scope.row.small
                      .map((e) => ["N", "Y"][e.key])
                      .join("")
                      }}
                    </span>
                    <div class="train_list">
                      <div
                        class="d_item"
                        v-for="(item1, index1) in scope.row.train"
                        :key="index1"
                        :class="{ err: item1.key == 2 }"
                      >
                        <p v-if="item1.key != 2">{{ item1.txt }}</p>
                        <van-popover
                          v-else
                          v-model="item1.showPopover"
                          trigger="click"
                          :closeOnClickAction="false"
                          :getContainer="
                            () => {
                              $refs.more;
                            }
                          "
                        >
                          <div class="pop">
                            <div
                              class="pop_item"
                              v-for="(item2, index2) in item1.list"
                              :key="index2"
                            >{{ item2.txt }}</div>
                          </div>
                          <template #reference>
                            <p>{{ item1.txt }}</p>
                          </template>
                        </van-popover>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="score"
                label="分数"
                min-width="5%"
                align="center"
                v-if="
                  $route.query.teach_type == 1 || $route.query.teach_type == 2
                "
              ></el-table-column>
              <el-table-column
                prop="modality"
                label="辅助形式"
                min-width="9%"
                align="center"
                v-if="
                  $route.query.teach_type == 1 || $route.query.teach_type == 2
                "
              >
                <template slot-scope="scope">
                  <p>
                    {{
                    [
                    "",
                    "位置辅助",
                    "视觉辅助",
                    "示范辅助",
                    "手势辅助",
                    "眼表辅助",
                    "身体辅助",
                    ][scope.row.modality]
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                prop="edict"
                label="指令"
                min-width="7%"
                align="center"
                v-if="
                  $route.query.teach_type == 1 || $route.query.teach_type == 2
                "
              >
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="look('指令', scope.row.edict)"
                    type="text"
                    size="small"
                  >查看</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="notes" label="备注" min-width="7%" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="look('备注', scope.row.notes)"
                    type="text"
                    size="small"
                  >查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table v-if="activeTab === 1" :data="courseDetail.list" stripe>
              <el-table-column prop="main_name" label="维度" min-width="9%" align="center">
                <template slot-scope="scope">
                  <p
                    v-html="
                      scope.row.manor_name + '><br>' + scope.row.main_name
                    "
                  ></p>
                </template>
              </el-table-column>
              <el-table-column prop="project_name" label="项目" min-width="16%" align="center"></el-table-column>
              <el-table-column
                prop="target"
                label="目标"
                min-width="16%"
                align="center"
                v-if="
                  $route.query.teach_type == 3 || $route.query.teach_type == 4
                "
              ></el-table-column>
              <el-table-column prop="is_adopt" label="复习结果" min-width="8%" align="center">
                <template slot-scope="scope">
                  <p>{{ ["未通过", "通过"][scope.row.is_adopt] }}</p>
                </template>
              </el-table-column>
              <el-table-column
                prop="edict"
                label="指令"
                min-width="8%"
                align="center"
                v-if="
                  $route.query.teach_type == 1 || $route.query.teach_type == 2
                "
              >
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="look('指令', scope.row.edict)"
                    type="text"
                    size="small"
                  >查看</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="notes" label="备注" min-width="8%" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="look('备注', scope.row.notes)"
                    type="text"
                    size="small"
                  >查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="lookShow" round style="width:6rem" @close="lookShow = false">
      <div class="look_box">
        <div class="look_head">
          <p>{{lookInfo.title}}</p>
          <span @click="lookShow = false">
            <van-icon name="cross" />
          </span>
        </div>
        <div class="look_content" v-if="lookInfo.content">{{lookInfo.content}}</div>
        <div v-else>
          <van-empty description="暂无数据" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import "@/plugin/element";
export default {
  data() {
    return {
      activeTab: 0,
      courseDetail: {},
      lookShow: false,
      lookInfo: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    tabChange(val) {
      // this.courseDetail = {};
      this.activeTab = val;
      this.getList();
    },
    async getList() {
      let path =
        this.activeTab == 1 ? "pad/pad/rev-item" : "pad/pad/train-item";
      let params = {
        sunhost_id: this.$route.query.sunhost_id,
      };
      const res = await this.$http.get(path, { params: params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.courseDetail = res.data;
    },
    look(title, content) {
      this.lookShow = true
      this.lookInfo.title = title
      this.lookInfo.content = content
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0;
}
.image {
  font-size: 0;
  line-height: 0;
}
img {
  width: 100%;
}
.van-nav-bar {
  background: rgba(255, 255, 255, 0.3);
  /deep/ .van-nav-bar__left,
  .van-nav-bar__right {
    font-size: 0.16rem;
    color: #333333;
    .van-nav-bar__text {
      color: #333;
    }
  }
  /deep/ .van-nav-bar__left {
    font-size: 0.14rem;
  }
  &::after {
    border: none;
  }
}
.coursedetail_page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat #fbf7f7;
  min-height: 100vh;
  .coursedetail_con {
    padding: 0 0.2rem;
    margin-top: 0.16rem;
    height: calc(100vh - 0.62rem);
    .total {
      height: 0.48rem;
      background: #ffffff;
      border-radius: 0.08rem;
      line-height: 0.48rem;
      ul {
        display: flex;
        justify-content: space-around;
        li {
          font-size: 0.18rem;
          color: #1c1c39;
          i {
            color: #ff5f4c;
            margin-right: 0.1rem;
          }
        }
      }
    }
    .list {
      background: #fff;
      border-radius: 0.08rem;
      padding: 0.2rem;
      margin-top: 0.16rem;
      .tabs {
        margin-bottom: 0.2rem;
        border-bottom: 0.01rem solid #e2e2e2;
        .tab {
          display: inline-block;
          font-size: 0.16rem;
          color: #666;
          margin: 0 0.25rem -0.01rem 0;
          padding: 0 0.1rem 0.08rem;
          &.active {
            color: #f14947;
            border-bottom: 0.01rem solid #f14947;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .table_box {
        height: calc(100vh - 0.73rem);
        background: #fff;
        border-radius: 0.08rem 0.08rem 0 0;
        letter-spacing: 0;
        /deep/ table {
          &.el-table__header tr th {
            background: #ffeeee;
            font-size: 0.14rem;
            color: #333;
            font-weight: normal;
            border-left: 0.01rem solid #e8e8e8;
            &:first-child {
              border-left: 0.01rem solid #ffeeee;
            }
            &:last-child {
              border-right: 0.01rem solid #ffeeee;
            }
          }
          td,
          th.el-table__cell {
            border-color: #e8e8e8;
          }
          &.el-table__body td {
            border-left: 0.01rem solid #e8e8e8;
            &:last-child {
              border-right: 0.01rem solid #e8e8e8;
            }
          }
        }
        .el-table {
          border-radius: 0.08rem 0.08rem 0px 0px;
        }
        /deep/ .el-table__body-wrapper {
          max-height: calc(100vh - 1.7rem);
          overflow-y: scroll;
          .cell {
            font-size: 0.13rem;
            padding: 0 0.09rem;
            .el-button {
              width: 0.5rem;
              height: 0.28rem;
              border-radius: 0.14rem;
              background: #ffebeb;
              color: #f14947;
            }
          }
        }
        .train {
          display: flex;
          align-items: center;
        }
        .train_list {
          display: flex;
          flex-wrap: wrap;
          margin-left: 0.1rem;
          flex: 1;
        }
        .d_item {
          width: 0.36rem;
          line-height: 0.24rem;
          text-align: center;
          background: #f3f3f3;
          border-radius: 0.05rem;
          border: 0.01rem solid #d9d9d9;
          margin: 0.03rem;
          &.err {
            background: #ffe8e8;
            border: 1px solid #ffbcbc;
            color: #ff5f4c;
            .pop {
              display: flex;
              padding: 0.12rem 0.06rem;
              .pop_item {
                width: 0.42rem;
                height: 0.24rem;
                background: #f3f3f3;
                border: 0.01rem solid #d9d9d9;
                margin: 0 0.06rem;
                border-radius: 0.12rem;
              }
            }
          }
        }
      }
    }
  }
}
.look_head {
  height: 0.42rem;
  line-height: 0.42rem;
  padding: 0 0.14rem;
  background: url("~@/assets/img/form_head.png") top left/100% 100% no-repeat;
  p {
    font-size: 0.16rem;
    color: #750100;
    text-align: center;
  }
  span {
    position: absolute;
    top: 0;
    right: 0.14rem;
    font-size: 0.16rem;
    color: #750100;
  }
}
.look_content {
  padding: 0.14rem;
  font-size: 0.14rem;
  color: #666;
  line-height: 24px;
  word-break: break-word;
}
</style>