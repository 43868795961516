var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"coursedetail_page"},[_c('van-nav-bar',{attrs:{"left-text":"❮返回"},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"coursedetail_con"},[_c('div',{staticClass:"total"},[_c('ul',[_c('li',[_c('i',{staticClass:"el-icon-folder"}),_c('span',[_vm._v("项目："+_vm._s(_vm.courseDetail.item_num))])]),_c('li',[_c('i',{staticClass:"el-icon-folder-remove"}),_c('span',[_vm._v("训练中项目："+_vm._s(_vm.courseDetail.train_num))])]),_c('li',[_c('van-icon',{attrs:{"name":"completed"}}),_c('span',[_vm._v("通过项目："+_vm._s(_vm.courseDetail.adopt_num))])],1),_c('li',[_c('van-icon',{attrs:{"name":"todo-list-o"}}),_c('span',[_vm._v("复习项目："+_vm._s(_vm.courseDetail.rev_num))])],1)])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab",class:{ active: _vm.activeTab === 0 },on:{"click":function($event){return _vm.tabChange(0)}}},[_vm._v("训练项目")]),_c('div',{staticClass:"tab",class:{ active: _vm.activeTab === 1 },on:{"click":function($event){return _vm.tabChange(1)}}},[_vm._v("复习项目")])]),_c('div',{staticClass:"table_box"},[(_vm.activeTab === 0)?_c('el-table',{attrs:{"data":_vm.courseDetail.list,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"main_name","label":"维度","min-width":"11%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{domProps:{"innerHTML":_vm._s(
                    scope.row.manor_name + '><br>' + scope.row.main_name
                  )}})]}}],null,false,1985711143)}),_c('el-table-column',{attrs:{"prop":"project_name","label":"项目","min-width":"16%","align":"center"}}),(
                _vm.$route.query.teach_type == 3 || _vm.$route.query.teach_type == 4
              )?_c('el-table-column',{attrs:{"prop":"target","label":"目标","min-width":"30%","align":"center"}}):_vm._e(),(
                _vm.$route.query.teach_type == 3 || _vm.$route.query.teach_type == 4
              )?_c('el-table-column',{attrs:{"prop":"train","label":"目标完成情况","min-width":"10%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(["", "独立完成", "半辅", "全辅"][scope.row.train&&scope.row.train[0].key])+" ")])]}}],null,false,230742080)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"is_adopt","label":"项目状态","min-width":"8%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.$route.query.teach_type == 1 || _vm.$route.query.teach_type == 2)?_c('p',[_vm._v(_vm._s(["训练中", "已通过"][scope.row.is_adopt]))]):_vm._e(),(_vm.$route.query.teach_type == 3 || _vm.$route.query.teach_type == 4)?_c('p',[_vm._v(_vm._s(["训练中", "已通过"][scope.row.is_train]))]):_vm._e()]}}],null,false,1406808862)}),(
                _vm.$route.query.teach_type == 1 || _vm.$route.query.teach_type == 2
              )?_c('el-table-column',{attrs:{"prop":"train","label":"训练记录","min-width":"26%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"train"},[_c('span',[_vm._v(" "+_vm._s(scope.row.big && scope.row.big.length > 0 ? scope.row.big.map(function (e) { return ["N", "Y"][e.key]; }).join("") : scope.row.small && scope.row.small .map(function (e) { return ["N", "Y"][e.key]; }) .join(""))+" ")]),_c('div',{staticClass:"train_list"},_vm._l((scope.row.train),function(item1,index1){return _c('div',{key:index1,staticClass:"d_item",class:{ err: item1.key == 2 }},[(item1.key != 2)?_c('p',[_vm._v(_vm._s(item1.txt))]):_c('van-popover',{attrs:{"trigger":"click","closeOnClickAction":false,"getContainer":function () {
                            _vm.$refs.more;
                          }},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('p',[_vm._v(_vm._s(item1.txt))])]},proxy:true}],null,true),model:{value:(item1.showPopover),callback:function ($$v) {_vm.$set(item1, "showPopover", $$v)},expression:"item1.showPopover"}},[_c('div',{staticClass:"pop"},_vm._l((item1.list),function(item2,index2){return _c('div',{key:index2,staticClass:"pop_item"},[_vm._v(_vm._s(item2.txt))])}),0)])],1)}),0)])]}}],null,false,3229907682)}):_vm._e(),(
                _vm.$route.query.teach_type == 1 || _vm.$route.query.teach_type == 2
              )?_c('el-table-column',{attrs:{"prop":"score","label":"分数","min-width":"5%","align":"center"}}):_vm._e(),(
                _vm.$route.query.teach_type == 1 || _vm.$route.query.teach_type == 2
              )?_c('el-table-column',{attrs:{"prop":"modality","label":"辅助形式","min-width":"9%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s([ "", "位置辅助", "视觉辅助", "示范辅助", "手势辅助", "眼表辅助", "身体辅助" ][scope.row.modality])+" ")])]}}],null,false,3677981588)}):_vm._e(),(
                _vm.$route.query.teach_type == 1 || _vm.$route.query.teach_type == 2
              )?_c('el-table-column',{attrs:{"prop":"edict","label":"指令","min-width":"7%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.look('指令', scope.row.edict)}}},[_vm._v("查看")])]}}],null,false,2268291602)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"notes","label":"备注","min-width":"7%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.look('备注', scope.row.notes)}}},[_vm._v("查看")])]}}],null,false,3633762850)})],1):_vm._e(),(_vm.activeTab === 1)?_c('el-table',{attrs:{"data":_vm.courseDetail.list,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"main_name","label":"维度","min-width":"9%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{domProps:{"innerHTML":_vm._s(
                    scope.row.manor_name + '><br>' + scope.row.main_name
                  )}})]}}],null,false,1985711143)}),_c('el-table-column',{attrs:{"prop":"project_name","label":"项目","min-width":"16%","align":"center"}}),(
                _vm.$route.query.teach_type == 3 || _vm.$route.query.teach_type == 4
              )?_c('el-table-column',{attrs:{"prop":"target","label":"目标","min-width":"16%","align":"center"}}):_vm._e(),_c('el-table-column',{attrs:{"prop":"is_adopt","label":"复习结果","min-width":"8%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(["未通过", "通过"][scope.row.is_adopt]))])]}}],null,false,3472404279)}),(
                _vm.$route.query.teach_type == 1 || _vm.$route.query.teach_type == 2
              )?_c('el-table-column',{attrs:{"prop":"edict","label":"指令","min-width":"8%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.look('指令', scope.row.edict)}}},[_vm._v("查看")])]}}],null,false,2268291602)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"notes","label":"备注","min-width":"8%","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.look('备注', scope.row.notes)}}},[_vm._v("查看")])]}}],null,false,3633762850)})],1):_vm._e()],1)])])],1),_c('van-popup',{staticStyle:{"width":"6rem"},attrs:{"round":""},on:{"close":function($event){_vm.lookShow = false}},model:{value:(_vm.lookShow),callback:function ($$v) {_vm.lookShow=$$v},expression:"lookShow"}},[_c('div',{staticClass:"look_box"},[_c('div',{staticClass:"look_head"},[_c('p',[_vm._v(_vm._s(_vm.lookInfo.title))]),_c('span',{on:{"click":function($event){_vm.lookShow = false}}},[_c('van-icon',{attrs:{"name":"cross"}})],1)]),(_vm.lookInfo.content)?_c('div',{staticClass:"look_content"},[_vm._v(_vm._s(_vm.lookInfo.content))]):_c('div',[_c('van-empty',{attrs:{"description":"暂无数据"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }